<!-- 选择居民弹窗-->
<template>
  <div>
    <el-dialog
      title="选择居民"
      :visible.sync="visible"
      width="40%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1%;
        "
      >
        <!-- 左边竖线样式写法 -->
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
          "
        >
          <div style="display: flex; align-items: center; margin-bottom: 2%">
            <div style="border-left: 4px solid #556bff">&nbsp;</div>
            <div class="search-title">{{ tableText }}人员列表</div>
          </div>
        </div>
        <div style="display: flex; align-items: center">
          <a-select
            style="width: 200px; margin: 1% 0"
            :default-value="typeData[0]"
            placeholder="特殊妇女"
            @change="typeChange"
            allowClear
          >
            <a-select-option
              v-for="(type, index) in typeData"
              :key="index"
              :value="type.value"
            >
              {{ type.label }}
            </a-select-option>
          </a-select>
          <ax-button @click="receive" style="margin-left: 2%">保存</ax-button>
        </div>
      </div>

      <!-- 基本信息 -->
      <a-table
        :data-source="tableData"
        :columns="columns"
        :row-selection="{
          type: 'radio',
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        bordered
        :pagination="pagination"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            placeholder="请输入姓名"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            查询
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
          >
            重置
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
      </a-table>
    </el-dialog>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from "./api";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      api,
      tableData: [],
      total: "",
      visible: false,
      columns: [
        {
          title: "姓名",
          dataIndex: "residentName",
          key: "residentName",
          width: 80,
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          onFilter: (value, record) =>
            record.residentName
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: "身份证号",
          dataIndex: "identityCard",
          ellipsis: true,
          width: 100,
        },
        {
          title: "现住区域",
          dataIndex: "currentArea",
          ellipsis: true,
          width: 120,
        },
      ],
      selectedRowKeys: [],
      selectedRow: [],
      typeData: [],
      peopleType: "1",
      tableText: "特殊妇女",
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      pagination: {
        defaultPageSize: 5,
        showTotal: (total) => `共 ${total} 条数据`,
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "15", "20"],
        onShowSizeChange: (current, pageSize) => (this.pageSize = pageSize),
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 打开弹窗
    async openModal(record = {}) {
      this.visible = true;
      const res = await api.womanList();
      this.tableData = res.data.records;
      this.total = res.data.records.total;
      this.$nextTick(() => {
        this.selectedRowKeys = [];
        this.getDictionsary();
      });
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false;
    },
    // 人员分类
    async typeChange(e) {
      console.log(e, "223");
      this.peopleType = e;
      switch (e) {
        case "1":
          this.tableText = "特殊妇女";
          const res = await api.womanList();
          this.tableData = res.data.records;
          this.total = res.data.records.total;
          break;
        case "2":
          this.tableText = "特殊儿童";
          const res1 = await api.childrenList();
          this.tableData = res1.data.records;
          this.total = res1.data.records.total;
          break;
        case "3":
          this.tableText = "空巢老人";
          const res2 = await api.elderlyList();
          this.tableData = res2.data.records;
          this.total = res2.data.records.total;
          break;
        case "4":
          this.tableText = "退役军人";
          const res3 = await api.veteranList();
          this.tableData = res3.data.records;
          this.total = res3.data.records.total;
          break;
        case "5":
          this.tableText = "精神病患者";
          const res4 = await api.psychopathList();
          this.tableData = res4.data.records;
          this.total = res4.data.records.total;
          break;
        case "6":
          this.tableText = "刑满释放";
          const res5 = await api.emancipistlist();
          this.tableData = res5.data.records;
          this.total = res5.data.records.total;
          break;
        case "7":
          this.tableText = "社区矫正";
          const res6 = await api.rectificationList();
          this.tableData = res6.data.records;
          this.total = res6.data.records.total;
          break;
        case "8":
          this.tableText = "涉邪";
          const res7 = await api.drugList();
          this.tableData = res7.data.records;
          this.total = res7.data.records.total;
          break;
        case "9":
          this.tableText = "特殊妇女";
          const res8 = await api.cultList();
          this.tableData = res8.data.records;
          this.total = res8.data.records.total;
          break;
        case "10":
          this.tableText = "特殊未成年";
          const res9 = await api.minorsList();
          this.tableData = res9.data.records;
          this.total = res9.data.records.total;
          break;
        case "11":
          this.tableText = "重点维稳信访";
          const res10 = await api.petitionList();
          this.tableData = res10.data.records;
          this.total = res10.data.records.total;
          break;
        default:
          this.tableText = "特殊妇女";
          const res12 = await api.womanList();
          this.tableData = res12.data.records;
          this.total = res12.data.records.total;
          break;
      }
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
      console.log(row[0].residentId, "95");
      this.selectedRow = row[0];
      this.selectedRow.type = this.peopleType;
    },
    // 获取字典
    async getDictionsary() {
      // 人员类别
      await api.dictData({ dicKind: "resident_type" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.typeData = options;
      });
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    // 保存
    receive() {
      if (this.selectedRowKeys.length == "0") {
        this.visible = true;
        this.$message.warning("请选择居民");
      } else {
        this.visible = false;
        this.$emit("receive", this.selectedRow);
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}
</style>